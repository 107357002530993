import { captureException } from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FC, useEffect } from 'react';
import useSWR, { Fetcher } from 'swr';
import shallow from 'zustand/shallow';

import { getProfile } from 'src/data/ProfileApi/ProfileApi';
import { getLdUserGroup } from 'src/general/helpers/getLdUserGroup';
import { getOrdersIgnoreError } from 'src/services/OrderService';
import { authStore, getUserAuthDataFromCookies } from 'src/stores/authStore';
import { pushToDataLayerWithEnhancedConversion } from 'src/utils/pushToDataLayer';

const DEFAULT_FOCUS_THROTTLE = 60 * 1000;

export const AuthManager: FC = () => {
  const {
    isUserLoggedIn,
    userData,
    userAuthDataLoading,
    setUserDataLoading,
    setUserAuthData,
    setUserData,
    setUserOrdersLoading,
    setUserOrders,
  } = authStore(
    (state) => ({
      isUserLoggedIn: state.isUserLoggedIn,
      userData: state.userData,
      userAuthDataLoading: state.userAuthDataLoading,
      setUserDataLoading: state.setUserDataLoading,
      setUserAuthData: state.setUserAuthData,
      setUserData: state.setUserData,
      setUserOrdersLoading: state.setUserOrdersLoading,
      setUserOrders: state.setUserOrders,
    }),
    shallow,
  );
  const ldClient = useLDClient();

  useEffect(() => {
    const userAuthData = getUserAuthDataFromCookies();
    setUserAuthData(userAuthData);
  }, []);

  useEffect(() => {
    const ldContext = ldClient?.getContext();

    getLdUserGroup(userData?.id).then((userGroup) => {
      let newContextKey = userGroup;
      if (userData?.email?.includes('carma.com.au') && isUserLoggedIn) {
        newContextKey = 'user-carma';
      }
      // Only update the context if needed
      if (ldContext && ldContext.key && ldContext.key !== newContextKey) {
        ldClient?.identify({
          key: newContextKey,
          kind: 'user',
        });
      }
    });
  }, [userData, isUserLoggedIn]);

  const loadUserProfile: Fetcher<Awaited<ReturnType<typeof getProfile> | null>, {}> = async () => {
    setUserDataLoading();
    for (let retries = 0; retries < 3; retries++) {
      try {
        // Load the user's profile
        const result = await getProfile();
        setUserData(result);
        hubspotIdentifyUser(result.email);
        gtmIdentifyUser(result.email, result.phone);
        return result;
      } catch (error) {
        // If a 401 error happens, signout the user
        if (error instanceof Error && /code 401/i.exec(error.message) && typeof window !== 'undefined') {
          window.location.href = `/api/auth/signout?redirect=${encodeURIComponent(window.location.href)}`;
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
          captureException(error);
        }
      }
    }
    return null;
  };

  const loadOrdersData: Fetcher<Awaited<ReturnType<typeof getOrdersIgnoreError>>, {}> = async () => {
    setUserOrdersLoading();
    // Orders API call
    const result = await getOrdersIgnoreError();
    setUserOrders(result ?? []);
    return result;
  };

  useSWR(isUserLoggedIn && !userAuthDataLoading ? 'AuthManager-/me/profile' : null, loadUserProfile, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
  });

  // Handle loading and refreshing of the cart API data
  useSWR(isUserLoggedIn && userData && !userAuthDataLoading ? 'AuthManager-/me/orders' : null, loadOrdersData, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
  });

  return null;
};

const hubspotIdentifyUser = (email: string) => {
  let _hsq = (window._hsq = window._hsq ?? []);
  _hsq.push(['identify', { email }]);
};

const gtmIdentifyUser = (email: string, phone: string = '') => {
  pushToDataLayerWithEnhancedConversion(email, phone, {
    event: 'user_authenticated',
  });
};
