import { isEmpty, isNil } from 'lodash';

import { UserContext } from 'src/authentication/templates/AuthForm/AuthForm.machine';
import { getPhoneNumberE164 } from 'src/general/helpers/getPhoneNumberE164';
import { getSha256Hash } from 'src/general/helpers/getSha256Hash';
import { getUserAuthDataFromCookies } from 'src/stores/authStore';
import { BaseCarData } from 'src/types/Car.types';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { ProductDetailsPayload } from 'src/types/ProductDetail.type';

import { getOr } from './getOr';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

/* eslint-disable camelcase */
interface DataLayerProduct {
  item_id: string;
  item_name: string;
  currency: string;
  index: number;
  item_brand: string | null;
  item_category: string | null;
  item_category2: string | null;
  item_category3: string | null;
  item_category4: string | null;
  item_category5: string | null;
  item_variant: string | null;
  price: number;
  quantity: number;
}
/* eslint-enable camelcase */

type PushToDataLayerConfig = {
  throttleWait: number;
  key: string | null;
};

const defaultConfig: PushToDataLayerConfig = {
  throttleWait: 500,
  key: null,
};

let throttles: string[] = [];

/**
 * Push data to GTM's data layer
 * @param data Object
 */
export const pushToDataLayer = (data: Object, config: Partial<PushToDataLayerConfig> = {}) => {
  const combinedConfig = { ...defaultConfig, ...config };
  const key = combinedConfig.key ?? JSON.stringify(data);

  if (typeof window !== 'undefined') {
    // Initialise dataLayer if needed
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [];
    }
    if (throttles.indexOf(key) < 0 || combinedConfig.throttleWait === 0) {
      // Push data to the dataLayer array
      window.dataLayer.push(data);
      throttles.push(key);
      if (combinedConfig.throttleWait !== 0) {
        setTimeout(() => {
          throttles = throttles.filter((value) => value === key);
        }, combinedConfig.throttleWait);
      }
    } else if (process.env.APP_ENVIRONMENT !== 'production') {
      /* eslint-disable no-console */
      console.group('GTM event deduplicated');
      console.log(data);
      console.log(
        `If the event isn't intended to be deduplicated, please update the throttleWait config for your event`,
      );
      console.groupEnd();
      /* eslint-enable no-console */
    }
  }
};

export const pushToDataLayerWithEnhancedConversion = (email: string, phone: string = '', data: Object) => {
  const promises = [getSha256Hash(email)];
  if (!isEmpty(phone)) {
    promises.push(getSha256Hash(getPhoneNumberE164(phone)));
  }
  Promise.all(promises).then((results) => {
    // eslint-disable-next-line camelcase
    const [sha256_email_address, sha256_phone_number] = results;
    const enhancedConversionData = {
      sha256_email_address,
      sha256_phone_number,
      ...data,
    };

    pushToDataLayer(enhancedConversionData);
  });
};

/**
 * Format a product detail to a GTM dataLayer ecommerce product object
 * @param productDetail
 * @returns
 */
export const productDetailToDataLayer = (
  productDetail: ProductDetailsPayload,
  index: number = 0,
): DataLayerProduct => ({
  item_id: productDetail.sku,
  item_name: productDetail.description,
  currency: 'AUD',
  index,
  item_brand: productDetail.make,
  item_category: productDetail.model,
  item_category2: productDetail.body,
  item_category3: productDetail.transmission,
  item_category4: productDetail.ancapRating,
  item_category5: `${productDetail.buildYear}`,
  item_variant: productDetail.colour,
  price: parseInt(`${productDetail.price}`.replace('$', '').replace(',', ''), 10),
  quantity: 1,
});

const getAttributeFromCartItem = (cartItem: any, attributeName: string, additionalPath: string = ''): string | null => {
  // Attributes array
  const attributesArray: { name: string; value: any }[] = getOr(cartItem, 'variant.attributes', []);

  return getOr(
    attributesArray.find((item) => getOr(item, 'name', '') === attributeName)!,
    `value${additionalPath}`,
    null,
  );
};

export const baseCarDataToDataLayerItem = (carData: BaseCarData) => ({
  index: 0,
  quantity: 1,
  item_id: carData.sku,
  item_name: carData.model,
  price: carData.price,
  item_brand: carData.makeModel[0],
  item_category: carData.makeModel[1],
  item_category2: carData.bodyType,
  item_category3: carData.transmission,
  item_category4: carData.ancapRating,
  item_category5: carData.buildYear,
  item_variant: carData.colour,
});

/**
 * Parse a cart item to a GTM dataLayer ecommerce product object
 * @param cartItem any (no types available yet)
 */
export const cartItemToDataLayer = (cartItem: any, index: number = 0): DataLayerProduct => {
  let ancapRating = getAttributeFromCartItem(cartItem, 'ancap_rating');
  if (ancapRating !== null) {
    ancapRating = `${ancapRating} stars`;
  }

  return {
    item_id: getOr(cartItem, 'productKey', ''),
    item_name: getOr(cartItem, 'name.en-AU', ''),
    currency: 'AUD',
    index,
    item_brand: '', // Make not available in cartItem object
    item_category: '', // Model not available in cartItem object
    item_category2: getAttributeFromCartItem(cartItem, 'body_type', '.label'),
    item_category3: getAttributeFromCartItem(cartItem, 'transmission', '.label'),
    item_category4: ancapRating,
    item_category5: getAttributeFromCartItem(cartItem, 'build_year'),
    item_variant: getAttributeFromCartItem(cartItem, 'colour', '.label'),
    price: getOr(cartItem, 'price.value.centAmount', 0) / 100,
    quantity: 1,
  };
};

/* eslint-disable camelcase */
export const enum DataLayerWatchlistEvent {
  watchlist_add_attempt = 'watchlist_add_attempt',
  watchlist_successful_add = 'watchlist_successful_add',
  watchlist_remove = 'watchlist_remove',
  watchlist_undos = 'watchlist_undos',
}

export type DataLayerWatchlistPayload = {
  isLoggedIn: boolean;
  user: UserContext | null;
  sku: string | null;
  product: Partial<ProductListDataRow>;
  triggerEvent: 'logged_in' | 'successful_login' | 'successful_account_creation' | null;
};

type Source = 'HOME' | 'PLP' | 'PDP' | 'WATCHLIST' | undefined;

export const generateSourceString = (pathname: string) => {
  if (pathname === '/') {
    return 'HOME';
  }
  if (pathname.includes('/used-cars')) {
    return 'PLP';
  }
  if (pathname.includes('/product-detail')) {
    return 'PDP';
  }
  if (pathname.includes('/watchlist')) {
    return 'WATCHLIST';
  }

  return undefined;
};

export const getDefaultSource = () => {
  const { pathname, searchParams } = new URL(window.location.href);
  let source = searchParams.get('source') as Source;
  if (!source) {
    source = generateSourceString(pathname);
  }
  return source;
};

export const productDetailToListDataRow = (productDetails: ProductDetailsPayload) => ({
  sku: productDetails.sku,
  title: productDetails.description,
  make: productDetails.make,
  model: productDetails.model,
  body: productDetails.body,
  transmission: productDetails.transmission,
  ancapRating: productDetails.ancapRating,
  colour: productDetails.colour,
  buildYear: productDetails.buildYear,
  price: productDetails.price.toString(),
  state: productDetails.state,
});

export const pushToDataLayerWatchList = (event: DataLayerWatchlistEvent, data?: Partial<DataLayerWatchlistPayload>) => {
  const userAuthData = getUserAuthDataFromCookies();
  const width = window.innerWidth;

  const getSku = () => {
    const { searchParams } = new URL(window.location.href);
    return searchParams.get('sku') || data?.sku || null;
  };

  pushToDataLayer({
    event,
    value: {
      isLoggedIn: !isNil(userAuthData),
      user: userAuthData
        ? {
            id: userAuthData.id,
          }
        : null,
      sku: getSku(),
      ecommerce: {
        items: [
          {
            index: 0,
            item_id: data?.product?.sku,
            item_name: data?.product?.title,
            currency: 'AUD',
            item_brand: data?.product?.make,
            item_category: data?.product?.model,
            item_category2: data?.product?.body,
            item_category3: data?.product?.transmission,
            item_category4: data?.product?.ancapRating,
            item_category5: data?.product?.buildYear,
            item_variant: data?.product?.colour,
            price: data?.product?.price,
          },
        ],
      },
      triggerEvent: data?.triggerEvent ?? !isNil(userAuthData) ? 'logged_in' : null,
      device: width < 550 ? 'mobile' : 'desktop',
      source: getDefaultSource(),
      date: new Date().toISOString(),
      ...data,
    },
  });
};

/* eslint-disable camelcase */
export const enum DataLayerPdpEvent {
  pdp_cta_interaction = 'pdp_cta_interaction',
  pdp_gallery_interaction = 'pdp_gallery_interaction',
  pdp_imperfections_interaction = 'pdp_imperfections_interaction',
  pdp_service_history_interaction = 'pdp_service_history_interaction',
}

export type DataLayerPdpPayload = {
  option: Option;
  sku: string | null;
};

type Option =
  | 'PRICE_GUIDE'
  | 'OPEN_DOOR'
  | 'CLOSE_DOOR'
  | 'EXPAND'
  | 'INTERIOR'
  | 'GALLERY'
  | 'IMPERFECTIONS'
  | 'CHAT_OPEN'
  | 'CHAT_CLOSE'
  | 'VIEW_360'
  | 'EXTERIOR'
  | 'NEXT'
  | 'PREVIOUS'
  | undefined;

export const pushToDataLayerPDP = (event: DataLayerPdpEvent, data?: Partial<DataLayerPdpPayload>) => {
  const width = window.innerWidth;
  pushToDataLayer({
    event,
    value: {
      ...data,
      device: width < 550 ? 'mobile' : 'desktop',
      date: new Date().toISOString(),
    },
  });
};

/* eslint-disable camelcase */
export const enum DataLayerFinanceCalculatorEvent {
  finance_calculator_switch_tab = 'finance_calculator_switch_tab',
  edit_weekly_payment = 'edit_weekly_payment',
  edit_deposit_amount = 'edit_deposit_amount',
  edit_term_of_loan = 'edit_term_of_loan',
  edit_interest_rate = 'edit_interest_rate',
  edit_purchase_price = 'edit_purchase_price',
}

type FinanceCalculatorTab = 'WHAT_CAN_I_AFFORD' | 'MY_REPAYMENTS';

export type DataLayerFinanceCalculatorPayload = {
  tab: FinanceCalculatorTab;
  value?: any;
};

export const pushToDataLayerFinanceCalculator = (
  event: DataLayerFinanceCalculatorEvent,
  data?: Partial<DataLayerFinanceCalculatorPayload>,
) => {
  pushToDataLayer({
    event,
    value: {
      ...data,
      date: new Date().toISOString(),
    },
  });
};
