'use client';

import { SnackbarProvider } from 'notistack';
import { FC, ReactNode } from 'react';

import { IconTick } from 'src/general/Icons/IconTick';

import classes from './NotificationProvider.module.scss';

interface Props {
  children: ReactNode;
}

export const NotificationProvider: FC<Props> = ({ children }) => (
  <SnackbarProvider
    iconVariant={{
      success: <IconTick />,
      info: <IconTick />,
    }}
    autoHideDuration={3000}
    preventDuplicate
    className={classes.notification}
  >
    {children}
  </SnackbarProvider>
);
