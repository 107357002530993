import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import 'dialog-polyfill-universal';
import 'dialog-polyfill/dist/dialog-polyfill.css';
import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { FC, useEffect } from 'react';
import 'styles/fonts.css';
import 'styles/globals.css';
import 'styles/helperClasses.scss';

import ErrorBoundary from 'src/components/ErrorBoundary';
import { PreviewSnackbar } from 'src/general/components/PreviewSnackbar/PreviewSnackbar';
import { RouterHistoryManager } from 'src/general/components/RouterHistoryManager/RouterHistoryManager';
import { ThirdPartyLibraries } from 'src/general/components/ThirdPartyLibraries/ThirdPartyLibraries';
import { WebVitals } from 'src/general/components/WebVitals/WebVitals';
import { WebsiteHelpers } from 'src/general/components/WebsiteHelpers/WebsiteHelpers';
import { initCarmaEventTracker, pushCarmaEvent, removeCarmaEventTracker } from 'src/general/helpers/carmaCritical';
import { getInitialLdUserGroup } from 'src/general/helpers/getLdUserGroup';
import { NotificationProvider } from 'src/general/providers/NotifcationProvider/NotificationProvider';
import { StoreProviders } from 'src/general/providers/StoreProviders/StoreProviders';
import cmsGraphClient from 'src/services/instance/cmsGraphClient';
import { CarmaErrorStoreProvider, useCreateCarmaErrorStore } from 'src/stores/errorStore';
import { MicrocopyStoreProvider, useCreateMicrocopyStore } from 'src/stores/microcopyStore';
import { PlpStoreLegacyProvider, useCreatePlpStoreLegacy } from 'src/stores/plpStoreLegacy';
import { RouterHistoryStoreProvider, useCreateRouterHistoryStore } from 'src/stores/routerHistoryStore';
import { themeCreated } from 'src/theme';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const { pathname, asPath, query } = useRouter();

  const createRouterHistoryStore = useCreateRouterHistoryStore({});
  const createPlpStore = useCreatePlpStoreLegacy(pageProps.initialPlpStoreState);
  const createMicrocopyStore = useCreateMicrocopyStore(
    pageProps.initialMicrocopyStoreState,
    pageProps.contentfulMicrocopy,
  );

  const createCarmaErrorStore = useCreateCarmaErrorStore(pageProps.contentfulCarmaError);

  function checkStyleLoaded() {
    const jssStyles: any = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  const persistSessionInfo = () => {
    // eslint-disable-next-line camelcase
    const { utm_medium: utmMedium, utm_source: utmSource, utm_campaign: utmCampaign } = query;
    if (utmSource) {
      window.sessionStorage.setItem('utmSource', utmSource as string);
    }
    if (utmMedium) {
      window.sessionStorage.setItem('utmMedium', utmMedium as string);
    }
    if (utmCampaign) {
      window.sessionStorage.setItem('utmCampaign', utmCampaign as string);
    }
  };

  useEffect(() => {
    initCarmaEventTracker();
    checkStyleLoaded();

    return () => {
      removeCarmaEventTracker();
    };
  }, []);

  useEffect(() => {
    persistSessionInfo();
  }, [query]);

  useEffect(() => {
    pushToDataLayer({ event: 'navigation', pathname: asPath });
    pushCarmaEvent({ event: 'navigation' });
  }, [pathname, asPath]);

  return (
    <>
      <Head>
        <title>Carma | The easy way to buy used cars in Australia</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        {/* https://nextjs.org/docs/messages/react-hydration-error#common-ios-issues */}
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
      </Head>
      <WebVitals />
      <WebsiteHelpers />
      <ThemeProvider theme={themeCreated}>
        <NotificationProvider>
          <CssBaseline />
          <NextNProgress color="#E83657" options={{ showSpinner: false }} />
          {pageProps.preview && <PreviewSnackbar />}
          <ApolloProvider client={cmsGraphClient}>
            {/* Global functionality related provider */}
            <RouterHistoryStoreProvider createStore={createRouterHistoryStore}>
              {/* Content related providers */}
              <MicrocopyStoreProvider createStore={createMicrocopyStore}>
                <CarmaErrorStoreProvider createStore={createCarmaErrorStore}>
                  <PlpStoreLegacyProvider createStore={createPlpStore}>
                    {/* Auth related providers */}
                    <StoreProviders>
                      <ThirdPartyLibraries />
                      {/* Store managers */}
                      <RouterHistoryManager />
                      {/* Content */}
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </StoreProviders>
                  </PlpStoreLegacyProvider>
                </CarmaErrorStoreProvider>
              </MicrocopyStoreProvider>
            </RouterHistoryStoreProvider>
          </ApolloProvider>
        </NotificationProvider>
      </ThemeProvider>
    </>
  );
};

export default withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
  context: {
    kind: 'user',
    key: getInitialLdUserGroup(),
  },
  options: {
    logger: basicLogger({ level: process.env.APP_ENVIRONMENT === 'production' ? 'warn' : 'info' }),
  },
})(MyApp as FC<{}>);
