/* eslint-disable implicit-arrow-linebreak */
import { forEach } from 'lodash';

import { DAYS, DEFAULT_GGMAP_REGION, DEFAULT_SEPARATOR, DEFAULT_THE_COMMA, MONTHS } from 'src/constants';

import { isNumber } from './filterHelper';

const cachedFields = {};

export function getGlobalFontSize(defaultIfError = 17) {
  if (cachedFields.fontSize === undefined) {
    const fontSizeString = global?.window?.getComputedStyle(document.body).fontSize;

    if (fontSizeString?.endsWith('px')) {
      cachedFields.fontSize = +fontSizeString.replace('px', '');
    }
  }

  return cachedFields.fontSize > 0 ? cachedFields.fontSize : defaultIfError;
}

function toString(value) {
  return (value || '').toString();
}

export function toPx(originValue) {
  const value = toString(originValue);

  if (value.endsWith('px')) {
    return +value.replace('px', '');
  }

  if (value.endsWith('rem')) {
    return +value.replace('rem', '') * getGlobalFontSize();
  }

  if (value.endsWith('em')) {
    /* eslint-disable-next-line */
    console.warn('em is not supported');

    return +value.replace('em', '') * getGlobalFontSize();
  }

  return +value;
}

/*
 * Returns a string with an error code from commerce tools removed.
 * Example '[error code 42] Something something error message' will
 * transform to 'Something something error message'
 */
export function stripErrorCode(err) {
  return err?.replace(/\[.*]/, '');
}

export const replaceThousandNumberForK = (num) => `$${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;

// Setting both minimumFractionDigits and maximumFractionDigits to 0 to fix the issue maximumFractionDigits is out of range error in Safari 14
/** @deprecated please use formatNumber from src/general/helpers/formatNumber.ts */
export function formatNumber(value, separator = DEFAULT_SEPARATOR, code = 'en-US', options = {}) {
  return (+`${value}`)
    .toLocaleString(code, {
      ...options,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    .replace(/,/g, separator);
}

export function formatNumberComma(value, separator = DEFAULT_THE_COMMA, code = 'en-US', options = {}) {
  return (+`${value}`)
    .toLocaleString(code, {
      ...options,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    .replace(/,/g, separator);
}

export function formatAmountWithCent(value, separator = DEFAULT_SEPARATOR, code = 'en-US', options = {}) {
  return (+`${value}`)
    .toLocaleString(code, {
      ...options,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .replace(/,/g, separator);
}

export function smartParseFloat(n, fractionDigits = 2) {
  return parseFloat(parseFloat(n).toFixed(fractionDigits));
}

export function sortByAlphabetically(data) {
  return data.sort((a, b) => a.localeCompare(b));
}

// https://superuser.com/questions/871404/what-would-be-the-the-mathematical-equivalent-of-this-excel-formula-pmt
export function calcP(rate, presentValue, years) {
  const APR = rate / 100;
  const Pv = presentValue;
  const Rw = APR / 52;
  const nw = years * 52;
  const Rm = APR / 12;
  const nm = years * 12;
  // eslint-disable-next-line
  const perWeek = (Pv * Rw) / (1 - 1 / (1 + Rw) ** nw);
  // eslint-disable-next-line
  const perMth = (Pv * Rm) / (1 - 1 / (1 + Rm) ** nm);

  return {
    perMonth: perMth,
    perWk: perWeek,
  };
}

export function safeObjectKeys(object) {
  forEach(object, (v, k) => {
    if (typeof v === 'object' || Array.isArray(object)) {
      object[k] = safeObjectKeys(v);
    } else if (isNumber(k)) {
      object[genSafeObjectKey(k)] = v;
      delete object[k];
    }
  });

  return object;
}

export function genSafeObjectKey(originKey) {
  return `${originKey}`.replace(/([0-9]+)/g, '~$1');
}

export function getFormattedDateDDMMYYY(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return [day, month, year].join('/');
}

export const deepCopy = (object) => JSON.parse(JSON.stringify(object));

export const getProductImgUrl = (sku) => {
  // Tactical fix, images API must be used to retrieve the Product card image
  // However this will need broader refactoring as, ideally, this API call needs to be done once for all cars in the catalogue
  // Talk to Flo if not sure
  if (sku) {
    if (sku.startsWith('0')) {
      sku = sku.substring(1);
    }
  }
  return `https://images.carma.com.au/${sku}/360doorsclosed/${sku}_XC_02.jpg`;
};

export const convertTimeString = (time) => {
  let seconds = time % 60;
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor(time / 60) - hours * 60;
  hours = hours.toString().length === 1 ? `0${hours}` : hours;
  minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  return `${hours}:${minutes}:${seconds}`;
};

export const getGGMapRegion = () => process.env.REACT_APP_REGION || DEFAULT_GGMAP_REGION;

export const GG_MAP_ADDRESS_URL = `${process.env.REACT_APP_GG_MAP_URL}/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GG_MAP_KEY}`;

export const convertHour12h = (hour) => {
  if (hour === 12) {
    return '12pm';
  }
  return hour > 12 ? `${hour % 12}pm` : `${hour}am`;
};

export const convertDeliveryDatetime = (dateString) => {
  const datetime = new Date(dateString);
  const fromHour = convertHour12h(datetime.getHours());
  const toHour = convertHour12h(datetime.getHours() + 2);

  const w = datetime.getDay();
  const d = datetime.getDate();
  const m = MONTHS[datetime.getMonth()];
  const y = datetime.getFullYear();

  return `${fromHour} - ${toHour}\n${DAYS[w]}, ${d <= 9 ? `0${d}` : d} ${m} ${y}`;
};

export const convertBirthDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export const getCurrentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

export const searchTextInArray = (textSearch, arrayToSearching) =>
  arrayToSearching.filter((e) => e.label.toLowerCase().includes(textSearch.toLowerCase()));

export const upperCaseFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getRegexForm = {
  phoneRegex: new RegExp(
    /^(?=.*)((?:61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?(0[1-9]|13|18)\)?) ?-?(?=.*)((\d{8}$)|(00 ?-?\d{8}$))/,
  ),
  nameRegex: new RegExp(/^[A-Za-z ,.'-]*$/),
  postalCodeRegex: new RegExp(/^[0-9]{4}$/),
  postalCodeSetLocationPopupRegex: new RegExp(/^[0-9]{3,}$/),
  driversLicenseRegex: new RegExp(/^[a-zA-Z0-9]{6,11}$/),
  charactersWithSpaceRegex: new RegExp(/^[A-Za-z\s]*$/),
};

export const handlePhoneNumberPrefix = (phoneNumber, currentPrefix, newPrefix) => {
  if (phoneNumber.substring(0, 2) === currentPrefix) {
    phoneNumber = `${newPrefix}${phoneNumber.substring(2)}`;
  }
  return phoneNumber;
};

export const isSameAddress = (addr1, addr2) => {
  if (!addr1 || !addr2) return false;
  return (
    addr1.streetNumber === addr2?.streetNumber &&
    addr1.streetName === addr2?.streetName &&
    addr1.city === addr2?.city &&
    addr1.state === addr2?.state
  );
};
