import { forEach } from 'lodash';

import { getGlobalFontSize } from '../utils/utils';

const config = {
  // font size
  textXs: '0.75rem',
  textSm: '0.875rem',
  textMd: '1rem',
  textLg: '1.125rem',
  textXl: '1.25rem',
  text2Xl: '1.5rem',
  text3Xl: '1.875rem',
  text4Xl: '2.25rem',
  text5Xl: '3rem',
  text6Xl: '3.75rem',
  text7Xl: '4.5rem',
  text8Xl: '6rem',
  text9Xl: '8rem',
  // spacing
  space0: '0px',
  space1: '0.25rem',
  space2: '0.5rem',
  space3: '0.75rem',
  space4: '1rem',
  space5: '1.25rem',
  space6: '1.5rem',
  space7: '1.75rem',
  space8: '2rem',
  space9: '2.25rem',
  space10: '2.5rem',
  space11: '2.75rem',
  space12: '3rem',
  space14: '3.5rem',
  space15: '3.75rem',
  space16: '4rem',
  space18: '4.5rem',
  space19: '4.75rem',
  space20: '5rem',
  space22: '5.5rem',
  space24: '6rem',
  space25: '6.25rem',
  space28: '7rem',
  space32: '8rem',
  space36: '9rem',
  space40: '10rem',
  space44: '11rem',
  space48: '12rem',
  space52: '13rem',
  space56: '14rem',
  space60: '15rem',
  space64: '16rem',
  space80: '18.25rem',
  space100: '20rem',
  space140: '24rem',
  spacePx: '1px',
  space0_5: '0.125rem',
  space1_5: '0.375rem',
  space2_5: '0.625rem',
  space3_5: '0.875rem',
  space5_5: '1.385rem',
  space6_5: '1.625rem',
  space11_5: '2.875rem',
  space12_5: '3.125rem',
};

const spaceValues = {};
const spaceSampleValues = [];

forEach(config, (v, k) => {
  if (k.startsWith('space')) {
    const value = v.replace('em', '').replace('r', '');

    if ((+value).toString() === value) {
      spaceValues[value] = k;
      spaceSampleValues.push(+value);
    }
  }
});

spaceSampleValues.sort((a, b) => a - b);
const cachedEstimates = {};

const styleGuide = {
  ...config,
  estimate(px, htmlFontSize) {
    let htmlFontSize1 = htmlFontSize;

    if (htmlFontSize1 === undefined) {
      htmlFontSize1 = getGlobalFontSize();
    }

    if (cachedEstimates[px] !== undefined) {
      return cachedEstimates[px];
    }

    const findValue = px / htmlFontSize1;
    let i = 0;

    for (; i < spaceSampleValues.length; i += 1) {
      if (findValue <= spaceSampleValues[i]) {
        if (i > 0) {
          if (findValue - spaceSampleValues[i - 1] < spaceSampleValues[i] - findValue) {
            cachedEstimates[px] = config[spaceValues[spaceSampleValues[i - 1].toString()]];
          } else {
            cachedEstimates[px] = config[spaceValues[spaceSampleValues[i].toString()]];
          }
        } else {
          cachedEstimates[px] = config[spaceValues[spaceSampleValues[i].toString()]];
        }

        return cachedEstimates[px];
      }
    }

    cachedEstimates[px] = [px, 'px'].join('');

    return cachedEstimates[px];
  },
};

export default styleGuide;
