import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconTick: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path d="m10.942 15.405 5.906-6.442-.885-.81-5.482 5.98L8 11.652l-.849.848 2.925 2.924a.6.6 0 0 0 .866-.019Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-1.2 0a7.8 7.8 0 1 1-15.6 0 7.8 7.8 0 0 1 15.6 0Z"
    />
  </Icon>
);
