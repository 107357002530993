import { produce } from 'immer';
import { mapValues } from 'lodash';
import dynamic from 'next/dynamic';
import { Component, ReactNode, useLayoutEffect } from 'react';
// eslint-disable-next-line camelcase
import Cookies from 'universal-cookie';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

import { COOKIE_MICROCOPY_DEBUG } from 'src/constants';
import { ContentfulMicrocopy } from 'src/data/Contentful/ContentfulGetMicrocopyListing';
import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { MarkdownLegacy } from 'src/general/components/Markdown/MarkdownLegacy';
import { getMicrocopyMap } from 'src/general/helpers/microcopy';

const MicrocopyDebug = dynamic(() => import('src/general/components/Microcopy/MicrocopyDebug'));

const cookies = new Cookies();

/**
 *
 * Store to manage microcopy
 * Follows Zustand store implementation with Next.JS example here:
 * https://github.com/vercel/next.js/tree/canary/examples/with-zustand
 *
 */

interface MicrocopyStoreStateData {
  microcopy: Map<MICROCOPY, string>;
}

export interface MicrocopyStoreState extends MicrocopyStoreStateData {
  set: (fn: (state: MicrocopyStoreState) => void) => void;
  getMicrocopy: (key: MICROCOPY, parseMarkdown?: boolean, microcopyTokens?: { [token: string]: string }) => ReactNode;
}

let store: StoreApi<MicrocopyStoreState> | undefined;
const microcopyStoreContext = createContext<StoreApi<MicrocopyStoreState>>();

const getDefaultInitialState: (contentfulMicrocopy?: ContentfulMicrocopy[]) => MicrocopyStoreStateData = (
  contentfulMicrocopy?: ContentfulMicrocopy[],
) => {
  const microcopy = getMicrocopyMap(contentfulMicrocopy);

  return {
    microcopy,
  };
};

export const initialiseMicrocopyStore = (
  preloadedState: Partial<MicrocopyStoreStateData> = {},
  contentfulMicrocopy?: ContentfulMicrocopy[],
) =>
  create<MicrocopyStoreState>((set, get) => ({
    ...getDefaultInitialState(contentfulMicrocopy),
    ...preloadedState,
    set: (fn) => set(produce(fn)),
    getMicrocopy: (key, parseMarkdown = false, microcopyTokens) => {
      // Is the microcopy in debug mode
      if (cookies.get(COOKIE_MICROCOPY_DEBUG)) {
        // Debugging
        return <MicrocopyDebug value={key} parseMarkdown={parseMarkdown} microcopyTokens={microcopyTokens} />;
      }
      if (get().microcopy.has(key)) {
        // Handle the microcopy
        const microcopyDataBase = get().microcopy.get(key)!;

        // Handle the tokens replacement if any
        const microcopyData: string = microcopyTokens
          ? Object.entries(microcopyTokens).reduce(
              // Replace the tokens in the microcopy data
              (microcopyString, [tokenKey, tokenValue]) => microcopyString.replace(`{${tokenKey}}`, tokenValue),
              microcopyDataBase,
            )
          : microcopyDataBase;

        // Handle the markdown transformation
        if (parseMarkdown) {
          return <MicrocopyMarkdown microcopyData={microcopyData} />;
        }
        return microcopyData;
      }

      // Value not found
      return 'Microcopy not found';
    },
  }));

export const MicrocopyStoreProvider = microcopyStoreContext.Provider;

export const microcopyStore = microcopyStoreContext.useStore;

export const useCreateMicrocopyStore = (
  serverInitialState: Partial<MicrocopyStoreStateData>,
  contentfulMicrocopy?: ContentfulMicrocopy[],
) => {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initialiseMicrocopyStore(serverInitialState, contentfulMicrocopy);
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store);
  store = store ?? initialiseMicrocopyStore(serverInitialState, contentfulMicrocopy);
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      store!.setState(
        {
          // re-use functions from existing store
          ...store!.getState(),
          // but reset all other properties.
          ...serverInitialState,
        },
        true, // replace states, rather than shallow merging
      );
    }
  });

  return () => store!;
};

/**
 * Get the microcopy for a key.
 * @param key - The microcopy key.
 * @param parseMarkdown - Interpret the microcopy as markdown.
 * @param microcopyTokens - Optional dynamic tokens value.
 */
export const getMicrocopy: MicrocopyStoreState['getMicrocopy'] = (key, parseMarkdown = false, microcopyTokens) =>
  microcopyStore((state) => state.getMicrocopy(key, parseMarkdown, microcopyTokens));

/**
 * The same as getMicrocopy, but for multiple keys.
 * @param microcopyValues - Object of strings to arrays containing the information for each microcopy value.
 */
export const getMicrocopyBulk = (
  microcopyValues: Record<string | number, Parameters<MicrocopyStoreState['getMicrocopy']>>,
) => microcopyStore((state) => mapValues(microcopyValues, (args) => state.getMicrocopy(...args)));

// Add global method
if (typeof window !== 'undefined') {
  (window as any).carmaToggleMicrocopyDebug = () => {
    const expiryLength = 5 * 60 * 1000;
    if (cookies.get(COOKIE_MICROCOPY_DEBUG)) {
      // The remove method from cookie doesn't seem to work, using instead negactive expiry
      const expires = new Date(new Date().getTime() - expiryLength);
      cookies.set(COOKIE_MICROCOPY_DEBUG, false, { path: '/', secure: process.env.NODE_ENV === 'production', expires });
    } else {
      // Cookie expiry 5min
      const expires = new Date(new Date().getTime() + expiryLength);
      cookies.set(COOKIE_MICROCOPY_DEBUG, true, { path: '/', secure: process.env.NODE_ENV === 'production', expires });
    }

    window.location.reload();
  };
}

class MicrocopyMarkdown extends Component<{ microcopyData: string }> {
  toString() {
    return this.props.microcopyData;
  }
  render() {
    return <MarkdownLegacy>{this.props.microcopyData}</MarkdownLegacy>;
  }
}
