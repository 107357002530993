import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import shallow from 'zustand/shallow';

import { routerHistoryStore } from 'src/stores/routerHistoryStore';

export const RouterHistoryManager: FC = () => {
  const router = useRouter();
  const { setPreviousUrl } = routerHistoryStore((state) => ({ setPreviousUrl: state.setPreviousUrl }), shallow);

  const handleBeforeHistoryChange = () => {
    setPreviousUrl(window.location.href);
  };

  useEffect(() => {
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange);
    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, []);

  return null;
};
