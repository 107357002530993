import { FC, useEffect } from 'react';
import useSWR from 'swr';
import shallow from 'zustand/shallow';

import { CAR_PURCHASE_STATUS } from 'src/constants';
import { fetchUnavailableVehicles } from 'src/data/ProductApi/ProductApiListVehicles';

import { carStatusStore } from '../carStatusStore';

const REFRESH_INTERVAL_MS = 60000;

export const CarStatusManager: FC = () => {
  const { data } = useSWR('/api/v1/vehicles-unavailable', fetchUnavailableVehicles, {
    revalidateIfStale: false,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: REFRESH_INTERVAL_MS,
  });

  const setUnavailableCarsData = carStatusStore((state) => state.setUnavailableCarsData, shallow);

  useEffect(() => {
    if (data) {
      const unavailableCarsData: { [sku: string]: CAR_PURCHASE_STATUS } = {};
      data.results.forEach((item) => {
        unavailableCarsData[item.sku] = item.status;
      });
      setUnavailableCarsData(unavailableCarsData);
    }
  }, [data]);

  return null;
};
