'use client';

import { useRouter } from 'next/navigation';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import useSWR from 'swr';
import shallow from 'zustand/shallow';

import { addWatchlistItem, getWatchlistItems } from 'src/data/WatchListApi/WatchlistApi';
import { WatchlistItemResponse } from 'src/data/WatchListApi/WatchlistApi.types';
import { authStore } from 'src/stores/authStore';
import { watchlistStore } from 'src/stores/watchListStore';

export const WatchListManager: FC = () => {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const { isUserLoggedIn, userAuthDataLoading } = authStore(
    (state) => ({ isUserLoggedIn: state.isUserLoggedIn, userAuthDataLoading: state.userAuthDataLoading }),
    shallow,
  );
  const { emptyWatchlist, setWatchlistLoading, setWatchlistData } = watchlistStore(
    (state) => ({
      emptyWatchlist: state.emptyWatchlist,
      setWatchlistLoading: state.setWatchlistLoading,
      setWatchlistData: state.setWatchlistData,
    }),
    shallow,
  );

  const loadWatchlistData: () => Promise<void | WatchlistItemResponse[]> = async () => {
    setWatchlistLoading();

    const result = await getWatchlistItems();
    setWatchlistData(result ?? null);
    return result;
  };

  const addToWatchList: (sku: string) => Promise<void> = async (sku: string) => {
    const result = await addWatchlistItem({ sku });
    setWatchlistData(result ?? null);
  };

  useSWR(isUserLoggedIn && !userAuthDataLoading ? 'watchlist' : null, loadWatchlistData, {
    revalidateOnFocus: true,
    focusThrottleInterval: 30000,
  });

  useEffect(() => {
    const currentUrl = new URL(window.location.toString());
    const watchlistSku = currentUrl.searchParams.get('watchlistSku');
    if (isUserLoggedIn && !userAuthDataLoading && watchlistSku) {
      addToWatchList(watchlistSku).then(() => {
        enqueueSnackbar('Vehicle has been added to your watchlist.', {
          variant: 'success',
        });
      });
      currentUrl.searchParams.delete('watchlistSku');
      router.replace(currentUrl.toString());
    } else {
      // empty watchlist store when logged-out
      emptyWatchlist();
    }
  }, [isUserLoggedIn, userAuthDataLoading]);

  return null;
};
