import { NextRequest } from 'next/server';

/**
 * Helper function to get the website's base URL
 */
export const getBaseUrl = (request?: NextRequest): string => {
  if (request && request.nextUrl.hostname !== '0.0.0.0') {
    return request.nextUrl.toString();
  }
  if (process.env.BASE_URL) {
    return process.env.BASE_URL;
  }
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }
  return `http://localhost:${process.env.PORT ?? 3000}`;
};
