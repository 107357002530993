import { carmaBizClient } from 'src/services/instance/carmaBizClient';
import { FilterListPayload, FiltersPayloadRow, SortValues } from 'src/types/CarFilters.types';
import {
  PRODUCT_CATALOGUE_LIMIT,
  ProductListDataPayload,
  ProductListDataRow,
  UnavailableProductListDataPayload,
} from 'src/types/CataloguePage.types';

/**
 * Fetch a collection of vehicles
 */
export function listVehicleData(pageSize: number, page: number) {
  return carmaBizClient.get<ProductListDataPayload>(`/vehicles`, {
    headers: {
      'Content-Type': 'application/plp+json',
    },
    params: {
      pageSize,
      page,
    },
  });
}

/**
 * Fetch all the vehicles
 */
export async function listAllVehicleData() {
  const listAll: ProductListDataRow[] = [];
  const pageSize = 300;
  let totalReturnedItems = 0;
  let currentPage = 0;

  // Build the list of all the slugs
  do {
    // await in a loop is required in this case
    // eslint-disable-next-line no-await-in-loop
    const result = await listVehicleData(pageSize, currentPage);

    listAll.push(...result.data.results);

    totalReturnedItems = result.data.results.length;
    currentPage += 1;
  } while (totalReturnedItems === pageSize);
  return listAll;
}

export const fetchVehicleFilters = async () => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicle-filters`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/json' },
    next: {
      revalidate: 300,
      tags: ['plp', `plp-filters`],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as FilterListPayload;
};

export const fetchVehicles = async (
  filters: FiltersPayloadRow[] = [],
  page: number = 0,
  pageSize: number = PRODUCT_CATALOGUE_LIMIT,
  sort: SortValues = SortValues.Recommended,
) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'POST',
    body: JSON.stringify({
      filters,
      page,
      pageSize,
      sort,
    }),
    next: {
      revalidate: 5,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchWatchlistVehiclesBySku = async (skus: string[]) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles/watchlist`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/watchlist+json' },
    method: 'POST',
    body: JSON.stringify({ skus }),
    next: {
      revalidate: 5,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchVehiclesBySku = async (skus: string[]) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'POST',
    body: JSON.stringify({ skus }),
    next: {
      revalidate: 5,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchUnavailableVehicles = async () => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles-unavailable`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'GET',
    next: {
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as UnavailableProductListDataPayload;
};
