const siteUrl = process.env.SITE_URL || 'https://carma.com.au';

export const getLinkType = (url: string): 'internal' | 'external' | 'anchor' => {
  if (
    (url.startsWith('http') && !url.startsWith(siteUrl)) ||
    url.startsWith('mailto:') ||
    url.startsWith('tel:') ||
    url.startsWith('sms:')
  ) {
    return 'external';
  }
  if (url.startsWith('#')) {
    return 'anchor';
  }
  return 'internal';
};
